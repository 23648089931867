import React from 'react';
import { Link } from 'react-router-dom';
import { Common, EntityConstants, Router } from '../../api';
import { Source } from '../../api/source.service';
import { DesignerConstants, UnoCompEvents, UnoComponent, UnoComponentManager } from '../../core';
import { UnoCoreBaseComp } from './uno-core.base.comp';

@UnoComponent({
    id: 'Button',
    label: 'Button',
    group: DesignerConstants.PaletteGroup.Frequent.id,
    props: [
        {
            id: 'label',
            label: 'Label',
            groupID: 'General',
        },
        {
            id: 'navigateTo',
            label: 'Navigate To',
            groupID: 'General',
        },
        {
            id: 'title',
            label: 'Hint',
            groupID: 'General',
        },
        {
            groupID: 'General',
            id: 'input_id',
            label: 'ID',
            description: 'of the DOM Element',
        },
        {
            groupID: 'General',
            id: 'disable_focus',
            label: 'Disable Focus',
            dataType: EntityConstants.PropType.BOOLEAN,
        },
        {
            id: 'action',
            label: 'On Click',
            description: 'If specified, this gets priority',
            dataType: EntityConstants.PropType.FUNCTION,
            groupID: 'Events',
        },
        {
            id: 'event_keys',
            label: 'Keys to Act on',
            multiplicity: -1,
            groupID: 'Events',
        },
        {
            id: 'anchor_style',
            label: 'Anchor Styles',
            dataType: EntityConstants.PropType.JSON,
            groupID: 'Style',
        },
    ],
    paletteable: true,
    isContainer: true,
    events: [
        UnoCompEvents.onClick,
        UnoCompEvents.onEnter,
        UnoCompEvents.onKeyPress,
        // UnoCompEvents.onLoad,
        // UnoCompEvents.onUnLoad,
    ],
})
export class Button extends UnoCoreBaseComp {
    componentDidMount(): void {
        super.componentDidMount();
        window.addEventListener('keyup', this.handleOnKeyPress);
    }

    componentWillUnmount(): void {
        window.removeEventListener('keyup', this.handleOnKeyPress);
        super.componentWillUnmount();
    }

    buildComp() {
        let navigateTo: string = UnoComponentManager.inflate(this.state.navigateTo, [this.props], this.getDisplayMode());
        const label = UnoComponentManager.inflate(this.state.label, [this.props], this.getDisplayMode()) || (navigateTo ? `Go To ${navigateTo}` : 'Button');
        const title = this.state.title || label;
        const children = this.state.children;
        const style = Common.safeParse(this.state.style);
        const anchorStyle = Common.safeParse(this.state.anchor_style);
        const navLabel = children?.length > 0 ? children : label;

        const disabled = Common.safeParse(this.state.disabled);

        const onClickHandler = (e: any) => {
            const inputs = { theComp: this, target: e.target, evt: e };
            const action = this.state.action;
            // console.log('Function: ', Common.checkType.Function(action), ' String: ', Common.checkType.String(action));
            if (Common.checkType.Function(action)) {
                action(inputs);
            } else if (Common.checkType.String(action)) {
                const fn = Source.getFunction(action);
                if (fn) {
                    fn(inputs);
                }
            }

            if (this.props[UnoCompEvents.onClick]) {
                Common.notifyEvent(this, UnoCompEvents.onClick, inputs);
            }
        }


        let navigateToView: any = undefined;

        if (navigateTo) {
            navigateTo = navigateTo.replace(Router.getOrigin(), '');
            if (navigateTo.startsWith('#')) {
                navigateToView = navLabel;
            } else if (navigateTo.startsWith('http') || navigateTo.toLowerCase().startsWith('mailto:')) {
                navigateToView = (
                    <a
                        href={disabled ? 'javascript:;' : navigateTo}
                        onClick={onClickHandler}
                        style={{ width: '100%', height: '100%', ...anchorStyle }}
                        target='_blank'
                        title={title}
                    >
                        {navLabel}
                    </a>
                );
            } else {
                navigateToView = (
                    <Link
                        to={disabled ? 'javascript:;' : navigateTo}
                        onClick={onClickHandler}
                        style={{ width: '100%', height: '100%', ...anchorStyle }}
                        title={title}
                    >
                        {navLabel}
                    </Link>
                );
            }
        }

        const onClickButton = async (evt: any) => {
            if (navigateToView) {
                if (navigateTo && navigateTo.startsWith('#')) {
                    // evt.stopPropagation();
                    window.location.href = navigateTo;
                    // console.log('Navigating to hash: ', navigateTo);
                    onClickHandler(evt);
                }
            } else {
                onClickHandler(evt);
            }
        }

        const tabIndex = (this.state.disable_focus) ? -1 : undefined;
        return (
            <button
                key={Common.getUniqueKey('button_')}
                id={this.getInputID()}
                title={title}
                style={{ ...style, ...this.getStyles() }}
                className={`uno-button ${navigateToView ? 'uno-button-navigate' : ''} ${this.getStyleClasses()}`}
                disabled={disabled}
                tabIndex={tabIndex}

                onClick={
                    async (evt: any) => {
                        await onClickButton(evt);
                    }
                }

                onKeyUp={
                    (evt: any) => {
                        this.handleOnKeyPress(evt);
                        evt?.target?.blur();
                    }
                }
            >
                {navigateToView ? navigateToView : navLabel}
            </button>
        )

    }

    handleOnKeyPress = async (evt: any) => {
        const keys = this.state.event_keys || [];
        const onEnter = this.props[UnoCompEvents.onEnter];
        const onKeyPress = this.props[UnoCompEvents.onKeyPress];

        const evtKey = evt?.key;
        const evtKeyCode = '' + evt?.keyCode;
        // console.log('Key Event - ', evtKey, evtKeyCode);

        if (onEnter && (evtKey === 'Enter' || evtKeyCode === '13')) {
            Common.notifyEvent(this, UnoCompEvents.onEnter, { evt: evt, key: evtKey, keyCode: evtKeyCode });
        } else if (onKeyPress && keys?.length > 0 && (keys.includes(evtKey) || keys.includes(evtKeyCode))) {
            Common.notifyEvent(this, UnoCompEvents.onKeyPress, { evt: evt, key: evtKey, keyCode: evtKeyCode });
        }
    }

    setLabel = (label: string) => {
        this.reRender({ label: label });
    }

}