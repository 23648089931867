import React from 'react';
import Latex from 'react-latex-next';
import Markdown from 'react-markdown';
import { Link } from 'react-router-dom';
import { AppScreenService } from '../../../@uno-app/service/app.screen.service';
import { EntityCategoryService } from '../../../@uno-app/service/entity.category.service';
import { EntityCategory, EntityConstants, EntityProp, RemoteService } from '../../../@uno/api';
import { Common, Router } from '../../../@uno/api/common.service';
import { CalendarOps, DesignerConstants, UC, UnoCompEvents, UnoComponent, UnoComponentManager } from '../../../@uno/core';
import { SourceCodeEditor } from '../editors/prop-editor.comp';
import { BasePropViewer } from '../prop-base.comp';

@UnoComponent({
    id: 'PropViewer',
    label: 'Property Viewer',
    events: [UnoCompEvents.onLoad, UnoCompEvents.onUnLoad],
    paletteable: false,
    group: DesignerConstants.PaletteGroup.Viewer.id,
})
export class PropViewer extends BasePropViewer {

    buildValue() {
        let ValueElem = this.getViewer();
        const props = { ...this.props };
        const oProps = { ...this.getOtherProps(), hideLabel: true, }
        return (<ValueElem {...props} otherProps={oProps} key={this.getUniqueKey()} />);
    }

}

@UnoComponent({
    id: 'EmailViewer',
    label: 'Email ID Viewer',
    paletteable: true,
    group: DesignerConstants.PaletteGroup.Viewer.id,
})
export class EmailViewer extends BasePropViewer {

    buildValue() {
        let val = this.getDefaultValue();
        if (val) {
            val = '' + val;
            return (<a href={`mailto:${val}`} style={this.getStyles()}> {val} </a>);
        } else {
            return <UC.Empty />;
        }
    }
}

@UnoComponent({
    id: 'LinkViewer',
    label: 'Link Viewer',
    paletteable: true,
    props: [
        {
            id: 'label',
            label: 'Link Label',
        },
        {
            id: 'navigateTo',
            label: 'Navigate To',
        },
        {
            id: 'styles',
            label: 'Styles',
            dataType: EntityConstants.PropType.JSON,
        },
        {
            id: 'hideLabel',
            label: 'Hide Prop Name',
            dataType: EntityConstants.PropType.BOOLEAN,
        },
    ],
    group: DesignerConstants.PaletteGroup.Viewer.id,
    isContainer: true,
})
export class LinkViewer extends BasePropViewer {

    buildValue() {
        const style = this.getStyles();
        let url: string = this.getDefaultValue();
        if (!url || url.trim().length === 0) {
            url = this.state.navigateTo;
        }

        let label = this.state.label;
        const children = this.state.children;
        return (
            <UC.Button
                label={label}
                navigateTo={url}
                style={style}
            >
                {children}
            </UC.Button>
        );
    }
}

@UnoComponent({
    id: 'PasswordViewer',
    label: 'Password Viewer',
    paletteable: false,
    group: DesignerConstants.PaletteGroup.Viewer.id,
})
export class PasswordViewer extends BasePropViewer {

    buildValue() {
        let val: string = '' + this.getDefaultValue();
        val = val.replace(new RegExp('.', 'g'), '*');
        return (<span title='The value has been masked' style={this.getStyles()}> {val} </span>);
    }
}

@UnoComponent({
    id: 'NumberViewer',
    label: 'Number Viewer',
    paletteable: false,
    group: DesignerConstants.PaletteGroup.Viewer.id,
})
export class NumberViewer extends BasePropViewer {

    buildValue() {
        const defVal = this.getDefaultValue();
        let numVal: any = Number.parseFloat(defVal);
        if (numVal === undefined || isNaN(numVal)) {
            numVal = defVal;
        } else {
            const format = this.getFormat();
            if (format) {
                numVal = numVal.toLocaleString(
                    format.locale,
                    {
                        style: format.currency ? 'currency' : format.style,
                        currency: format.currency,
                        maximumFractionDigits: format.decimal,
                        unit: format.unit,
                        ...format,
                    }
                );
            }
            // console.log('Number formating: ', defVal, numVal);
        }
        return (<span style={this.getStyles()}> {numVal} </span>);
    }

    getFormat() {
        const formatSettings = super.getFormat();
        const format = formatSettings ? {
            locale: 'hi-IN',
            ...formatSettings,
        } : undefined;
        return format;
    }
}

@UnoComponent({
    id: 'BooleanViewer',
    label: 'Boolean Viewer',
    paletteable: false,
    group: DesignerConstants.PaletteGroup.Viewer.id,
})
export class BooleanViewer extends BasePropViewer {

    buildValue() {
        let val: any = this.getDefaultValue();
        // console.log(`Boolean value: `, val);
        return (<span style={this.getStyles()}> {((val === undefined || val === 'false') ? false : true) ? 'TRUE' : 'FALSE'} </span>);
    }
}

@UnoComponent({
    id: 'MultilineViewer',
    label: 'Multiline Text Viewer',
    paletteable: false,
    props: [
        // { id: 'isLatex', label: 'Is a LaTeX content?', dataType: EntityConstants.PropType.BOOLEAN, },
    ],
    events: [UnoCompEvents.onLoad, UnoCompEvents.onUnLoad],
    group: DesignerConstants.PaletteGroup.Viewer.id,
})
export class MultilineViewer extends BasePropViewer {
    addBR = (text: string) => {
        if (text?.length > 0) {
            text = text.replaceAll(new RegExp('[\n\r]', 'g'), '<br/>');
        }
        return text;
    }

    buildComp() {
        let text: string = this.getDefaultValue();
        if (text?.length > 0) {
            if (this.state.isLatex) {
                return (
                    <span key={this.getUniqueKey()} style={this.getStyles()} className={this.getStyleClasses()}>
                        <Latex>{this.addBR(text)}</Latex>
                    </span>
                );
            } else if (this.state.isMarkdown) {
                return (
                    <span key={this.getUniqueKey()} style={this.getStyles()} className={this.getStyleClasses()}>
                        <Markdown>{text}</Markdown>
                    </span>
                );
            } else {
                const htmlContent = {
                    __html: this.addBR(text),
                };
                return (<span dangerouslySetInnerHTML={htmlContent} key={this.getUniqueKey()} style={this.getStyles()} className={this.getStyleClasses()} />)
            }
        } else {
            return (<></>);
        }
    }

    setContent = (content?: string) => {
        this.setDefaultValue(content);
    }
}

@UnoComponent({
    id: 'SourceCodeViewer',
    label: 'Source Code Viewer',
    props: [
        { id: 'canEdit', label: 'Can Edit', dataType: EntityConstants.PropType.BOOLEAN, },
        {
            id: 'type', label: 'Type',
            viewer: 'OptionViewer', editor: 'OptionSelector',
            extras: {
                options: [
                    { id: 'javascript', label: 'JavaScript' },
                    { id: 'json', label: 'JSON' },
                    { id: 'css', label: 'CSS' },
                    { id: 'xml', label: 'XML' },
                    { id: 'html', label: 'HTML' },
                ]
            }
        },
    ],
    paletteable: true,
    group: DesignerConstants.PaletteGroup.Viewer.id,
})
export class SourceCodeViewer extends SourceCodeEditor {//extends BasePropViewer {

    buildValue() {
        return this.buildEditor(this.getDefaultValue(), !this.canEdit());
    }

    protected setChangedPropValue(value: any): void {
        // do nothing.
    }

    canEdit() {
        return (this.state.canEdit !== undefined) ? this.state.canEdit : false;
    }
}

@UnoComponent({
    id: 'StandardFunctionViewer',
    label: 'Standard Function Viewer',
    paletteable: false,
    group: DesignerConstants.PaletteGroup.Viewer.id,
})
export class StandardFunctionViewer extends BasePropViewer {
    buildValue() {
        let fnDef: any = Common.safeParse(this.getDefaultValue());
        const fnLabel = fnDef ? `${fnDef.name}${fnDef.description ? `: ${fnDef.description}` : ''}` : '';
        return (
            <span style={this.getStyles()}>
                {fnLabel}
            </span>
        );
    }
}

@UnoComponent({
    id: 'JSONViewer',
    label: 'JSON Viewer',
    paletteable: true,
    props: [
        { id: 'canEdit', label: 'Can Edit', dataType: EntityConstants.PropType.BOOLEAN, }
    ],
    group: DesignerConstants.PaletteGroup.Viewer.id,
})
export class JSONViewer extends SourceCodeViewer { // extends BasePropViewer {
    getEditorLanguage(): string {
        return 'json';
    }
}

@UnoComponent({
    id: 'CSSViewer',
    label: 'CSS Viewer',
    props: [
        { id: 'canEdit', label: 'Can Edit', dataType: EntityConstants.PropType.BOOLEAN, }
    ],
    paletteable: true,
    group: DesignerConstants.PaletteGroup.Viewer.id,
})
export class CSSViewer extends SourceCodeViewer { // extends BasePropViewer {

    getEditorLanguage(): string {
        return 'css';
    }
}

@UnoComponent({
    id: 'XMLViewer',
    label: 'XML Viewer',
    props: [
        { id: 'canEdit', label: 'Can Edit', dataType: EntityConstants.PropType.BOOLEAN, }
    ],
    paletteable: true,
    group: DesignerConstants.PaletteGroup.Viewer.id,
})
export class XMLViewer extends SourceCodeViewer { // extends BasePropViewer {

    getEditorLanguage(): string {
        return 'css';
    }
}

@UnoComponent({
    id: 'FileViewer',
    label: 'File Viewer',
    props: [
        {
            id: 'defaultValue', label: 'Source',
            dataType: EntityConstants.PropType.ENTITY, category: 'uno_file',
            editor: 'FileEditor', extras: {
                onLoadFile: async (eProp: any, fileData: any) => {
                    // eProp.defaultValue = fileData;
                    // console.log('Uploaded File: ', eProp, fileData);
                }
            }
        },
        { id: 'title', label: 'Title' },
        { id: 'styles', label: 'Styles', dataType: EntityConstants.PropType.JSON },
    ],
    paletteable: true,
    group: DesignerConstants.PaletteGroup.Frequent.id,
    events: [UnoCompEvents.onLoad, UnoCompEvents.onUnLoad, UnoCompEvents.onClick],
})
export class FileViewer extends BasePropViewer {

    buildComp() {
        return this.buildValue();
    }

    createImage = (src: string, name: string, fileEntity?: any) => {
        // console.log('Creating Image: ', src, name);
        const clickable = (this.props[UnoCompEvents.onClick] !== undefined);
        return (
            <img
                src={src}
                // crossOrigin='anonymous'
                // className='tool-icon'
                alt={name}
                title={name}
                className={` file-img ${clickable ? ' clickable ' : ' '} ${this.getStyleClasses()} `}
                style={{ ...this.getStyles(), }} // cursor: (clickable ? 'pointer' : 'default'), 
                onClick={
                    (evt: any) => {
                        if (clickable) {
                            Common.notifyEvent(this, UnoCompEvents.onClick, { src: src, file: fileEntity, target: evt.target });
                        }
                    }
                }
            // title='click to view original'
            />
        );
    }

    buildValue() {
        const FileType = {
            FILE: 'file',
            IMAGE: 'image',
            AUDIO: 'audio',
            VIDEO: 'video',
        }
        let type: string = FileType.FILE;

        const fileEntity = Common.safeParse(this.state.fileEntity);

        let mimeType: string | undefined = fileEntity?.mimetype;
        if (mimeType) {
            mimeType = mimeType.toLowerCase();
            if (mimeType.startsWith('image/')) {
                type = FileType.IMAGE;
            } else if (mimeType.startsWith('audio/')) {
                type = FileType.AUDIO;
            } else if (mimeType.startsWith('video/')) {
                type = FileType.VIDEO;
            }
        }

        let src = Common.safeParse(this.getSrc());
        if (!fileEntity && Common.checkType.String(src)) { // looks like a direct image URL
            src = UnoComponentManager.inflate(src, [this.props], this.getDisplayMode());
            type = FileType.IMAGE;
        }

        const link = fileEntity?.downlink ? RemoteService.getFullURL(fileEntity.downlink) : (Common.checkType.String(src) ? src : undefined);
        const fileName = this.state.title || fileEntity?.name || 'A File';

        // console.log(`${type} Viewer `, mimeType, fileName, link, src, fileEntity);
        let content = fileName;
        if (link) {
            switch (type) {
                case FileType.IMAGE:
                    content = this.createImage(link, fileName, fileEntity);
                    break;
                case FileType.AUDIO:
                    content = (
                        <>
                            <audio
                                controls={true}
                                src={link}
                                controlsList='nodownload'
                                title={fileName}
                            />
                        </>
                    );
                    break;
                case FileType.VIDEO:
                    content = (
                        <>
                            <video
                                controls={true}
                                src={link}
                                controlsList='nodownload'
                                title={fileName}
                                style={{ maxWidth: '100%', height: 'auto' }}
                            />
                        </>
                    );
                    break;
                default:
                    content = (<a href={link} target='_blank' rel='noreferrer'>{fileName}</a>);
                    break;
            }

            return (
                <span key={Common.getUniqueKey('file_viewer_')}>
                    {content}
                </span>
            );
        } else if (src && Common.checkType.Object(src) && src?.category === 'uno_file') {
            this.loadFileEntity(src);
            // console.log('Reload file: ', src);
        }

        return <UC.Empty />;
    }


    getSrc() {
        let imgSrc = this.getDefaultValue();
        if (!imgSrc) {
            imgSrc = this.props.defaultValue;
        }
        return imgSrc;
    }

    loadFileEntity = async (file?: any) => {
        file = Common.safeParse((file ? file : this.getSrc()));
        if (Common.checkType.String(file)) { // Doesn't look like a file entity;
            return;
        }

        const fEntity = EntityConstants.build(file);
        if (fEntity.getID()) {
            //const fileEntity = await EntityConstants.getEntityService(fEntity.getAppID(), fEntity.getCategoryID()).findByID(fEntity.getID());
            const fileEntity = await fEntity.reload();
            if (fileEntity) {
                // console.log('view file: ', this.fileEntity);
                this.reRender({ fileEntity: fileEntity });
            }
        }
    }

}

@UnoComponent({
    id: 'ImageViewer',
    label: 'Image Viewer',
    props: [
        { id: 'defaultValue', label: 'Source', dataType: EntityConstants.PropType.MULTILINE },
        { id: 'styles', label: 'Styles', dataType: EntityConstants.PropType.JSON },
    ],
    paletteable: false,
    group: DesignerConstants.PaletteGroup.Viewer.id,
    events: [UnoCompEvents.onLoad, UnoCompEvents.onUnLoad, UnoCompEvents.onClick],
})
export class ImageViewer extends FileViewer {
    // Dummy implementation
}

class BaseDateTimeViewer extends BasePropViewer {
    getDefaultValue() {
        const original = super.getDefaultValue();
        const ts: number = Number.parseInt(original);
        // console.log('The Date-Time Value: ', ts, original);
        if (isNaN(ts)) {
            return undefined;
        } else {
            return CalendarOps.format(this.getDate(ts), this.getFormat());
        }

    }

    getDate(ts: number) {
        return new Date(ts);
    }

    getFormat() {
        let format = super.getFormat();
        if (!format) {
            format = this.getDefaultFormat();
        }
        return format;
    }

    getDefaultFormat() {
        return CalendarOps.Constants.FULL_DATE_TIME_FORMAT;
    }
}

@UnoComponent({
    id: 'DateViewer',
    label: 'Date Viewer',
    paletteable: false,
    group: DesignerConstants.PaletteGroup.Viewer.id,
})
export class DateViewer extends BaseDateTimeViewer {

    getDefaultFormat() {
        return CalendarOps.Constants.FULL_DATE_FORMAT;
    }

}

@UnoComponent({
    id: 'TimeViewer',
    label: 'Time Viewer',
    paletteable: false,
    group: DesignerConstants.PaletteGroup.Viewer.id,
})
export class TimeViewer extends BaseDateTimeViewer {

    getDefaultFormat() {
        return CalendarOps.Constants.TIME_FULL_FORMAT;
    }

    getDate(ts: number): Date {
        return new Date(CalendarOps.toStartTimeOfDay() + ts);
    }
}

@UnoComponent({ id: 'DateTimeViewer' })
export class DateTimeViewer extends BaseDateTimeViewer {

    getDefaultFormat() {
        return CalendarOps.Constants.FULL_DATE_TIME_FORMAT;
    }

}

@UnoComponent({
    id: 'OptionViewer',
    label: 'Option Viewer',
    paletteable: false,
    group: DesignerConstants.PaletteGroup.Viewer.id,
})
export class OptionViewer extends BasePropViewer {
    buildValue() {
        let value = this.getDefaultValue();
        this.getExtraParams()?.options?.forEach(
            (o: any) => {
                if (o.id === value) {
                    value = o.label ? o.label : o.id;
                }
            }
        );
        // console.log('Option Viewer: ', value,)

        return (
            <span style={this.getStyles()}>{value}</span>
        )
    }

}

@UnoComponent({
    id: 'CategoryViewer',
    label: 'Category Viewer',
    paletteable: false,
    group: DesignerConstants.PaletteGroup.Viewer.id,
})
export class CategoryViewer extends BasePropViewer {

    buildComp(): JSX.Element {
        const catID = super.getDefaultValue();
        if (catID) {
            const appID = this.getAppID();
            const category = EntityCategoryService.getAppCategory(catID, appID);
            const catEntity = EntityConstants.create('uno_category_def', appID, catID);
            // catEntity.setID(catID);
            // catEntity.setAppID(appID);
            catEntity.setName(category ? `${category?.label} - ${category?.id}` : catID);
            if (category?.isCore) {
                return (<>{catEntity.getName()}</>);
            } else {
                return (
                    <Link to={Router.getViewRoute(catEntity)} className='entity-quickview'>
                        {catEntity.toString()}
                    </Link>
                )
            }
        } else {
            return catID;
        }
    }

}

@UnoComponent({
    id: 'SelectedPropertyViewer',
    label: 'Selected Property Viewer',
    paletteable: false,
    group: DesignerConstants.PaletteGroup.Viewer.id,
})
export class SelectedPropertyViewer extends BasePropViewer {
    getCatPropID = () => {
        return this.getExtraParams()?.catID;
    }

    getDependsOnProps(): string[] {
        const propIDs: Array<string> = [];
        if (this.getCatPropID()) {
            propIDs.push(this.getCatPropID());
        }
        return propIDs;
    }

    handleChangeInDependsOnProp(pid: string, from: any, to: any): void {
        if (pid === this.getCatPropID()) {
            this.reRender();
        }
    }

    buildComp() {
        const selPropID = this.getDefaultValue();
        // console.log(`Prop Selector - EProp - `, this.getEProp(), this.getEntity());
        let catID = this.getEProp()?.category;
        if (!catID) {
            const catPropID = this.getCatPropID();
            const entity = this.getEntity();
            if (catPropID && entity) {
                catID = entity.getValue(catPropID);
            }
        }

        let category: EntityCategory | undefined = undefined;
        if (catID) {
            category = EntityCategoryService.getAppCategory(catID);
        }

        // console.log(`Prop Selector Category : `, catID, category, category?.props);
        if (category && category.props) {
            let selectedProp: any = undefined;
            category.props.forEach(
                (prop: EntityProp) => {
                    if (prop.id === selPropID) {
                        selectedProp = prop;
                    }
                }
            );
            return selectedProp ? `${selectedProp?.label} - ${selectedProp?.id}` : selPropID;
        }

        return selPropID;
    }
}


@UnoComponent({
    id: 'CustomPropViewer',
    label: 'Custom Property Viewer',
    props: [
        { groupID: 'Config', id: 'screen', label: 'Screen Entity', dataType: EntityConstants.PropType.ENTITY, category: 'uno_screen_def' },
    ],
    paletteable: false,
    group: DesignerConstants.PaletteGroup.Editor.id,
})
export class CustomPropViewer extends BasePropViewer {

    componentDidMount(): void {
        super.componentDidMount();
        this.loadCustomScreen();
    }

    buildComp(): JSX.Element {
        // const superComp = super.buildComp();
        let layout = this.state.layout;
        if (layout) {
            // layout = { ...layout }; // duplicate to avoid effect of caching.
            const props = {
                entityPropOriginal: { ...this.state.entityProp },
                // ...this.state,
                entityProp: undefined,
                defaultValue: this.getDefaultValue(),
                baseViewer: this,
            };

            layout.props = props;

            // console.log('Custom Prop Viewer: ', layout);
            return (
                <>
                    <UC.LayoutRenderer
                        config={layout}
                        mode={DesignerConstants.Mode.LIVE}
                        key={Common.getUniqueKey('custom_prop_view_')}
                    />
                </>
            );
        } else {
            return super.buildComp();
        }
    }

    async loadCustomScreen(screen?: any) {
        screen = screen || this.state.screen || this.getOtherProps()?.viewerScreen || this.getExtras()?.viewerScreen || this.getExtraParams()?.viewerScreen;

        const screenDef = Common.safeParse(screen);
        if (screenDef) {
            const screen: any = Common.checkType.String(screenDef) ? { _id: screenDef, } : EntityConstants.build(screenDef);
            const appID = screen.app_id || this.getAppID();
            const layout = await AppScreenService.getScreenByID(screen._id, appID);
            // console.log('Loading Screen for property viewing: ', screen, appID);
            this.reRender({ layout: layout });
        }
    }
}