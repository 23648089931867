import React from 'react';
import { Common, ComponentDef, ContentConfig, DDM, DesignerConstants, UC, UnoComponent, UnoComponentManager, Images } from '../../core';
import { UnoCoreBaseComp } from './uno-core.base.comp';
import { EntityConstants } from '../../api';

const PaletteGroups: any = DesignerConstants.PaletteGroup;
const getPaletteGroup = DesignerConstants.getPaletteGroup;
const defaultGroupID = PaletteGroups.Other.id;

@UnoComponent({ id: 'ComponentPalette', label: 'Component Palette' })
export class ComponentPalette extends UnoCoreBaseComp {
    activDef: any;

    buildComp() {
        const compsByGroup: any = this.groupCompsByPalette();
        const compGroupIDs = Object.keys(compsByGroup);
        const tabIDs: Array<string> = [];
        Object.values(PaletteGroups).forEach((pg: any) => {
            if (compGroupIDs.includes(pg.id)) {
                tabIDs.push(pg.id);
            }
        });

        const tabs: Array<any> = tabIDs.map(
            paletteID => {
                // console.log(`Palette Group: `, paletteID);
                const defs: Array<any> = compsByGroup[paletteID];
                defs.sort((a: any, b: any) => {
                    const aSerial = (a.serialNo !== undefined) ? a.serialNo : 999999;
                    const bSerial = (b.serialNo !== undefined) ? b.serialNo : 999999;
                    return (aSerial >= bSerial) ? 1 : -1;
                });

                const { palette, label } = this.getPalette(paletteID);
                const maximized = palette?.maximized ? true : false;

                const comp = (
                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        {
                            defs.map(
                                (def: any) => {
                                    return this.buildPaletteTool(def);
                                }
                            )
                        }
                    </div>
                );

                return {
                    name: label,
                    comp: comp
                };

            }
        );

        return (
            <UC.Section
                title='Components'
                minimized={this.state.minimized}
                resizable={true}
                styles={{
                    padding: '10px'
                }}
            >
                {this.activDef ? (
                    <div style={{ padding: '10px' }}>
                        <span style={{ fontWeight: 'normal' }}> Selected: </span>
                        <div style={{ display: 'inline-block' }} className='designer-palette-tool'>
                            {this.buildPaletteItem(this.activDef)}
                        </div>
                    </div>
                ) : undefined}
                <UC.TabbedPane tabs={tabs} />
            </UC.Section>
        );
    }

    buildPaletteTool = (def: ComponentDef) => {
        const tool = (
            <div
                key={Common.getUniqueKey()}
                className='designer-palette-tool'
                title={def.label}
                draggable={true}
                onDragStart={
                    (e) => {
                        const config = new ContentConfig(def.id);
                        DDM.initDrag(config);
                    }
                }
                onClick={() => {
                    const onSelectedHandler = this.state.onSelected;
                    if (onSelectedHandler) {
                        this.activDef = def;
                        onSelectedHandler(def);
                        this.setState({});
                    }
                }}
            >
                {this.buildPaletteItem(def)}
            </div>
        );

        return tool;
    }

    buildPaletteItem = (def: any) => {
        let Help = this.buildHelpIcon(def);

        return (
            <div style={{ border: '1px solid lightgrey', borderRadius: '10px', padding: '5px', width: '70px', minHeight: '70px' }} className='center'>
                <div>
                    {this.buildCompIcon(def)}
                </div>
                <div style={{ fontSize: 'x-small', marginTop: '5px' }}>
                    {def.label}
                </div>
                <UC.Section styleClasses='right'>{Help}</UC.Section>
            </div>
        );
    }

    buildCompIcon = (def: any) => {
        return (
            <>
                <img src={def.icon} className='tool-icon' title={def.label} />
            </>
        );
    }

    buildHelpIcon(def: any) {
        let Help = undefined;

        const helpContent = Common.safeParse(def.help);
        if (helpContent) {
            const title = `Help: ${def.label}`;

            const dlgConfig: any = {
                title: title,
                styles: { border: '1px solid black', padding: '5px' },
                onClose: () => { },
            };

            if (Common.checkType.Object(helpContent)) {
                dlgConfig.layout = helpContent;
            } else {
                dlgConfig.content = helpContent
            }

            Help = (
                <UC.Button
                    title={title}
                    action={async () => {
                        Common.showDialog(dlgConfig);
                    }}
                    styleClasses='image-button'
                >
                    <span>&nbsp;</span>
                    <img src={Images.Icon.help} style={{ width: '16px', height: 'auto' }} alt='(?)' />
                    <span>&nbsp;</span>
                </UC.Button>
            );
        }
        return Help;
    }

    private groupCompsByPalette() {
        const compsByGroup: any = {};
        Object.values(PaletteGroups).forEach((group: any) => {
            // compsByGroup[group.id] = [];
        });

        const defs = UnoComponentManager.getDefs();
        //console.log('Comp Defs : ', defs);
        for (let def of defs) {
            if (def.paletteable) {
                const compList: Array<string> | undefined = Common.safeParse(this.state.compList);
                if (compList && compList?.length > 0 && compList.includes(def.id) === false) {
                    continue;
                }
                const group = def.group ? def.group : defaultGroupID;
                const compGroup = compsByGroup[group] ? compsByGroup[group] : [];
                compGroup.push(def);
                compsByGroup[group] = compGroup;
            }
        }

        return compsByGroup;
    }

    private getPalette(paletteID: string) {
        const palette = getPaletteGroup(paletteID);
        const label = palette ? (palette.label ? palette.label : palette.id) : 'Palette';
        return { palette, label };
    }
}